<template>
  <div>
    <b-card-actions action-refresh ref="mainCard" @refresh="fetchData">
      <div class="d-flex justify-content-between align-items-center mb-1">
        <div class="d-flex align-items-center">
          <label for="" class="mr-1" style="font-size: 14px">Sort</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-100"
          />

          <download-excel
            :data="exportItems"
            :fields="exportFields"
            :name="'Attendance Report'"
            :worksheet="'Attendance Report'"
            class="cursor-pointer"
          >
            <b-button variant="" size="sm" class="d-flex ml-1 export-btn">
              <feather-icon icon="FileIcon" size="14" class="text-success" />
              <label style="font-size: 14px; margin-left: 5px; cursor: pointer">
                Export
              </label>
            </b-button>
          </download-excel>
        </div>
        <div class="d-flex align-items-center">
          <label for="" class="mr-1" style="font-size: 14px">Search</label>
          <b-form-input
            id="searchInput"
            v-model="filter"
            size="sm"
            type="search"
            placeholder="Search"
          />
        </div>
      </div>
      <b-row>
        <b-col cols="12">
          <b-table
            striped
            hover
            responsive
            :per-page="perPage"
            :current-page="currentPage"
            :items="items"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :sort-direction="sortDirection"
            :filter="filter"
            @filtered="onFiltered"
          >
            <template #cell(employee)="data">
              <span
                v-b-modal="'detail_attendance_' + data.value"
                class="text-primary"
              >
                {{ data.value }}
              </span>
            </template>
            <template #cell(profile_photo_url)="data">
              <b-avatar :src="data.value" />
            </template>
          </b-table>
        </b-col>

        <b-col cols="12">
          <div class="d-flex justify-content-between align-items-center">
            <span>
              Showing
              {{ perPage > totalRows ? totalRows : perPage }} of
              {{ totalRows }} entries
            </span>
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="right"
              size="sm"
              class="my-0"
            />
          </div>
        </b-col>
      </b-row>

      <b-modal
        @shown="refresh"
        v-for="item in items"
        :key="item.id"
        :id="'detail_attendance_' + item.employee"
        size="lg"
        hide-footer
      >
        <div class="text-center mb-3" style="line-height: 1px">
          <h1 class="mb-2">Attendance</h1>
          <span>
            This is location for employee name <b>{{ item.employee }}</b>
          </span>
        </div>
        <div>
          <l-map :zoom="zoom" :center="[item.lang, item.longt]">
            <l-tile-layer :url="url" />
            <l-marker :lat-lng="[item.lang, item.longt]" :icon="icon">
              <l-popup>{{ item.employee }} here!</l-popup>
            </l-marker>
          </l-map>
        </div>
      </b-modal>
    </b-card-actions>
  </div>
</template>

<style>
.table thead th {
  white-space: nowrap;
}

.month-picker-input-container {
  width: 100% !important;
}

.month-picker-input-container .month-picker-input {
  width: 100% !important;
  padding: 0.78em;
}

.btn-outline-secondary {
  border-color: #e4e7ea;
  color: #5a8dee;
  background-color: #fff;
  border-radius: 0.25rem;
}

.export-btn {
  height: 2.142rem;
  background: #fff !important;
  border: 1px solid #d8d6de !important;
}
</style>

<script>
/* eslint-disable global-require */
import { LMap, LTileLayer, LMarker, LPopup } from "vue2-leaflet";
import { latLng, icon } from "leaflet";
import "leaflet/dist/leaflet.css";

import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import { MonthPickerInput } from "vue-month-picker";
export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    MonthPickerInput,
  },
  data() {
    return {
      filter_employee: null,
      employees: [],

      month_year: null,
      month: null,
      year: null,

      filter_department: null,
      departments: [],

      // table
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      fields: [
        {
          key: "profile_photo_url",
          label: "Avatar",
        },
        {
          key: "employee",
          label: "Employee",
        },
        { key: "clock_in", label: "Clock In", sortable: true },
        { key: "late", label: "Late", sortable: true },
        { key: "working_from", label: "Working From", sortable: "true" },
        { key: "break_in", label: "Break In", sortable: "true" },
        { key: "break_out", label: "Break Out", sortable: "true" },
        { key: "break_hours", label: "Break Hours", sortable: "true" },
        { key: "clock_out", label: "Clock Out", sortable: "true" },
        {
          key: "clock_out_address",
          label: "Clock Out Address",
          sortable: true,
        },
        { key: "work_hours", label: "Work Hours", sortable: true },
        { key: "overtime", label: "Overtime", sortable: true },
        { key: "totally", label: "Totally", sortable: true },
      ],
      items: [],

      // export excel
      exportFields: {
        "Employee Name": "employee",
        "Clock In": "clock_in",
        late: "late",
        "Working From": "working_from",
        "Break In": "break_in",
        "Break Out": "break_out",
        "Break Hours": "break_hours",
        "Clock Out": "clock_out",
        "Clock Out From": "clock_out_address",
        "Work Hours": "work_hours",
        Overtime: "overtime",
        Totally: "totally",
      },
      exportItems: [],

      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      zoom: 50,
      center: ["-6.2399957", "106.9214024"],
      markerLatLng: ["-6.2399957", "106.9214024"],
      icon: icon({
        iconUrl: require("@/assets/images/icons/loc.png"),
        iconSize: [32, 37],
        iconAnchor: [16, 37],
      }),
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    refresh() {
      setTimeout(() => {
        window.dispatchEvent(new Event("resize"));
      }, 0);
    },

    fetchData() {
      this.items = [];
      this.$refs["mainCard"].showLoading = true;

      this.callApi({
        method: "GET",
        url: "/attendances/getDataSpreadsheet",
        success: (response) => {
          response.result.map((item) => {
            this.items.push({
              employee: item.name,
              profile_photo_url: item.photo,
              clock_in: moment(item.clock_in_time)
                .locale("id")
                .format("YYYY/MM/DD HH:mm:ss"),
              late: `${item.late != null ? item.late : 0} Hours`,
              working_from: this.truncateText(item.working_from, 20),
              break_in: item.break_in != "" ? item.break_in : "-",
              break_out: item.break_out != "" ? item.break_out : "-",
              break_hours: `${
                item.break_hours != "" ? item.break_hours : 0
              } Hours`,
              clock_out:
                item.clock_out_time != ""
                  ? moment(item.clock_out_time)
                      .locale("id")
                      .format("YYYY/MM/DD HH:mm:ss")
                  : "-",
              clock_out_address:
                item.clock_out_addres != ""
                  ? this.truncateText(item.clock_out_addres, 20)
                  : "-",
              work_hours: `${
                item.work_hours != null ? item.work_hours : 0
              } Hours`,
              overtime: `${item.Overtime != "" ? item.Overtime : 0} Hours`,
              totally: `${item.Totally != "" ? item.Totally : 0} Hours`,
              lang: item.lang.replace(",", "."),
              longt: item.longt.replace(",", "."),
              // user: item.user,
            });

            this.exportItems.push({
              employee: item.name,
              clock_in: moment(item.clock_in_time)
                .locale("id")
                .format("YYYY/MM/DD HH:mm:ss"),
              late: `${item.late != null ? item.late : 0} Hours`,
              working_from: this.truncateText(item.working_from, 20),
              break_in: item.break_in != "" ? item.break_in : "-",
              break_out: item.break_out != "" ? item.break_out : "-",
              break_hours: `${
                item.break_hours != "" ? item.break_hours : 0
              } Hours`,
              clock_out:
                item.clock_out_time != ""
                  ? moment(item.clock_out_time)
                      .locale("id")
                      .format("YYYY/MM/DD HH:mm:ss")
                  : "-",
              clock_out_address:
                item.clock_out_addres != ""
                  ? this.truncateText(item.clock_out_addres, 20)
                  : "-",
              work_hours: `${
                item.work_hours != null ? item.work_hours : 0
              } Hours`,
              overtime: `${item.Overtime != "" ? item.Overtime : 0} Hours`,
              totally: `${item.Totally != "" ? item.Totally : 0} Hours`,
            });
          });
          this.totalRows = this.items.length;
          this.$refs["mainCard"].showLoading = false;
        },
        error: (error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Notification",
              icon: "BellIcon",
              text: "Failed to fetch data",
              variant: "error",
            },
          });
          this.$refs["mainCard"].showLoading = false;
        },
      });
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    monthYearSelected() {
      this.month = this.month_year.month;
      this.year = this.month_year.year;
    },
  },
};
</script>